<template>
  <v-container
    :key="pageKey"
    fluid
    style="
      background: rgba(255, 255, 255, 0.9);
      min-width: 100%;
      min-height: 95vh;
      padding: 10px 20px;
      position: relative;
    "
  >
    <h6
      @click="closePage"
      style="
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        position: absolute;
        padding-top: 2px;
        width: 90px;
        cursor: pointer;
      "
    >
      <v-icon style="position: relative; top: -1px">mdi-chevron-left</v-icon
      >kembali
    </h6>
    <div style="margin: 25px 0 65px 0">
      <div style="border-bottom: 1px solid rgba(0, 0, 0, 0.2)">
        <div
          class="d-flex justify-start align-start"
          style="position: relative"
        >
          <h4 class="fpb-title mb-2">DETAIL PENERIMAAN BARANG</h4>
          <v-tooltip bottom color="grey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                x-small
                text
                icon
                color="grey darken-2"
                @click="loadPage"
                style="position: relative; top: 12px"
              >
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>refresh page</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <div class="actionButton" v-if="detail != null">
            <!-- Admin -->

            <div>
              <div v-if="getUserProfile.employee.id == detail.employee_id">
                <v-btn
                  v-if="detail.status == 0 && isEdit"
                  @click.prevent="saveChange"
                  type="button"
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Save
                </v-btn>
                <v-btn
                  v-if="detail.status == 0 && !isEdit"
                  type="button"
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  @click.prevent="editDetail"
                  :loading="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Edit
                </v-btn>
                <v-btn
                  v-if="detail.status == 0 && !isEdit"
                  type="button"
                  elevation="0"
                  color="green"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                  @click.prevent="submitGr"
                >
                  Submit
                </v-btn>
                <v-btn
                  v-if="detail.status === 2"
                  type="button"
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  @click.prevent="setDraftGr"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Set Draft
                </v-btn>

                <v-btn
                  v-if="!isEdit && detail.status != 2"
                  type="button"
                  elevation="0"
                  color="pink"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  :disabled="loading"
                  small
                  @click.prevent="cancelGr"
                  :rounded="wWidth < 809"
                >
                  Cancel GR
                </v-btn>
                <v-btn
                  v-if="isEdit"
                  type="button"
                  elevation="0"
                  color="pink"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  @click.prevent="isEdit = !isEdit"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                >
                  Cancel Edit
                </v-btn>
              </div>
              <!-- <div v-if="isAllow"> -->
              <!-- <div v-if="detail.status == 1"> -->
              <!-- <div
                    v-if="
                      detail.approver_users.find(
                        (e) => e === getUserProfile.employee.email
                      ) === undefined
                    "
                  > -->
              <!-- <v-btn
                  type="button"
                  elevation="0"
                  color="green"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                  @click="confirmPayreq"
                >
                  Confirm
                </v-btn>
                <v-btn
                  type="button"
                  elevation="0"
                  color="pink"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :loading="loading"
                  :disabled="loading"
                  small
                  :rounded="wWidth < 809"
                  @click="rejectPayreq"
                >
                  Reject
                </v-btn> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- <div
                  v-if="
                    getUserProfile.level.find(({ id }) => id === '3') !==
                    undefined
                  "
                  class="d-flex"
                > -->

              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
      <v-row no-gutters v-if="detail != null">
        <v-col cols="4">
          <p style="font-size: 12px">
            Tgl. Pembuatan: {{ convertDate(detail.createdAt) }} |
            {{ detail.created_by_name }}
          </p>
        </v-col>
        <!-- <v-col cols="4">
          <p style="font-size: 12px">
            Tgl. Terima: {{ convertDate(detail.received_date) }} |
            {{ detail.received_by_name }}
          </p>
        </v-col> -->
        <v-col cols="8">
          <div class="d-flex justify-end">
            <p
              :style="`background-color: ${statusColor(detail.status)};
                color: white;
                font-weight: bold;
                margin: 5px 0 0 0;
                padding: 0 10px;
                border: 1px solid rgba(0, 0, 0, 0.6);
                width: 180px;
                text-align: center;`"
            >
              {{ statusName(detail.status) }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="detail != null" no-gutters style="margin-top: 40px">
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            No. Dokumen
          </p>

          <v-text-field
            :value="detail.document_no"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            PIC
          </p>

          <v-text-field
            :value="detail.employee_name"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Perusahaan
          </p>

          <v-text-field
            :value="detail.company_name"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Department
          </p>

          <v-text-field
            :value="detail.department_name"
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Diterima dari
          </p>

          <v-text-field
            :value="detail.received_from_vendor_name"
            readonly
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Diserahkan Oleh
          </p>

          <v-text-field
            v-model="detail.submitted_by_name"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Diterima Oleh
          </p>

          <v-text-field
            v-model="detail.received_by_name"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            Tanggal terima
          </p>

          <v-text-field
            v-model="detail.received_date"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            type="date"
            step="1"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            No. FPB
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openDetailFpb(detail.purchase_request)"
                  style="cursor: pointer"
                  x-small
                  color="primary"
                >
                  mdi-open-in-new
                </v-icon>
              </template>
              <span>Lihat detail FPB</span>
            </v-tooltip>
          </p>

          <v-text-field
            :value="
              detail.purchase_request != null
                ? detail.purchase_request.request_no
                : '-'
            "
            dense
            readonly
            style="font-size: 12px; height: 45px"
          />
        </v-col>

        <!-- <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            No. terima
          </p>

          <v-text-field
            v-model="detail.received_no"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col> -->

        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            No. PO
          </p>

          <v-text-field
            v-model="detail.po_no"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            No. Surat Jalan Vendor
          </p>

          <v-text-field
            v-model="detail.sj_no"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            dense
            style="font-size: 12px; height: 45px"
          /> </v-col
        >
        <!-- <v-col cols="12" md="3" class="d-flex flex-column pl-3 pr-3">
          <p
            class="text-left"
            style="
              margin: 0;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
            "
          >
            No. Form
          </p>

          <v-text-field
            v-model="detail.form_no"
            :outlined="isEdit"
            :clearable="isEdit"
            :readonly="!isEdit"
            dense
            style="font-size: 12px; height: 45px"
          />
        </v-col> -->
      </v-row>
      <v-card v-if="detail != null" style="margin-top: 40px">
        <v-tabs
          v-model="tab"
          background-color="rgb(240,240,240)"
          slider-color="primary"
          color="primary"
        >
          <v-tab v-for="item in items" :key="item.tab">
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
            style="padding: 20px"
          >
            <div style="min-height: 60vh">
              <div v-if="item.tab == 'Detail Barang'">
                <v-data-table
                  v-model="selectedData"
                  :loading="loading"
                  height="30vh"
                  mobile-breakpoint="0"
                  :dense="wWidth > 808 ? true : false"
                  style="cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.2)"
                  :headers="grHeaders"
                  :items="detail.goods_receipt_detail"
                  :items-per-page="10"
                  @click:row="rowClick"
                  :search="searchItem"
                  :custom-filter="customSearch"
                  :options.sync="options"
                  :show-select="
                    getUserProfile.level.find(({ id }) => id === '41') ===
                      undefined && detail.status == 0
                  "
                  item-key="id"
                >
                  <template v-slot:top>
                    <div
                      style="
                        border: thin solid rgba(0, 0, 0, 0.12);
                        cursor: auto;
                        padding: 0 15px;
                        height: 75px;
                        display: flex;
                        overflow: hidden;
                      "
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          margin: 0 15px 0 5px;
                        "
                      >
                        <v-btn
                          v-if="detail.status == 0"
                          @click="openItemForm"
                          style="font-size: 10px"
                          elevation="2"
                          tilerounded="5px"
                          x-small
                          color="primary"
                          class="white--text"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small class="">
                                mdi-plus
                              </v-icon>
                            </template>
                            <span>Tambah item</span>
                          </v-tooltip>
                        </v-btn>
                      </div>
                      <div style="width: 120px">
                        <v-select
                          :disabled="selectedData.length === 0"
                          label="Action"
                          style="
                            position: relative;
                            top: 15px;
                            margin: 0 15px 0 5px;
                            font-size: 12px;
                          "
                          v-model="actionValue"
                          :items="itemsAction"
                          item-text="name"
                          item-value="id"
                          outlined
                          return-id
                          dense
                          @change="actionSelect"
                        ></v-select>
                      </div>
                      <div
                        style="
                          width: 260px;
                          position: relative;
                          top: 15px;
                          margin: 0 15px 0 5px;
                          font-size: 12px;
                          height: 45px;
                        "
                      >
                        <v-text-field
                          v-model="searchItem"
                          label="Cari barang"
                          outlined
                          dense
                        ></v-text-field>
                      </div>
                      <v-spacer></v-spacer>
                      <!-- <div style="display: flex; align-items: center">
                        <v-btn
                          @click.prevent
                          type="button"
                          small
                          color="primary"
                          style="margin-right: 10px"
                        >
                          <v-icon color="white" small style="margin-right: 5px"
                            >mdi-file-outline</v-icon
                          >
                          <a href="#" style="font-weight: bold; color: white"
                            >Dokumen</a
                          >
                        </v-btn>
                        <v-btn
                          @click.prevent
                          type="button"
                          small
                          color="primary"
                          style="margin-right: 10px"
                        >
                          <v-icon color="white" small style="margin-right: 5px"
                            >mdi-magnify
                          </v-icon>
                          <a href="#" style="font-weight: bold; color: white"
                            >Lihat PDF</a
                          >
                        </v-btn>
                      </div> -->
                    </div>
                  </template>
                  <template v-slot:[`item.no`]="{ index }">
                    <div style="width: 35px">
                      {{ index + 1 }}
                    </div>
                  </template>
                  <template v-slot:[`item.payment_request`]="{ item }">
                    <div style="width: 145px; font-size: 12px">
                      {{
                        item.payment_request != null
                          ? item.payment_request.document_no
                          : '-'
                      }}
                    </div>
                  </template>
                  <template v-slot:[`item.product_code`]="{ item }">
                    <div style="width: 95px; font-size: 12px">
                      {{ item.product.code }}
                    </div>
                  </template>
                  <template v-slot:[`item.product_name`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          style="
                            text-align: left;
                            width: 250px;
                            font-size: 12px;
                            padding: 0;
                          "
                        >
                          {{
                            `${
                              item.product.code !== null
                                ? '(' + item.product.code + ')'
                                : ''
                            } ${item.product.name}`
                          }}
                        </div>
                      </template>
                      <span>
                        {{
                          `${
                            item.product.code !== null
                              ? '(' + item.product.code + ')'
                              : ''
                          } ${item.product.name}`
                        }}</span
                      >
                    </v-tooltip>
                  </template>
                  <!-- <template v-slot:[`item.qty_return`]="{ item }">
                    <div style="width: 45px; font-size: 12px">
                      {{ item.qty_return }}
                    </div>
                  </template> -->
                  <template v-slot:[`item.uom_name`]="{ item }">
                    <div style="width: 35px; font-size: 12px">
                      {{ item.uom_name }}
                    </div>
                  </template>
                  <!-- <template v-slot:[`item.qty_request`]="{ item }">
                    <div style="width: 50px; font-size: 12px">
                      {{ item.qty_request }}
                    </div>
                  </template> -->
                  <template v-slot:[`item.qty_done`]="{ item }">
                    <div style="width: 50px; font-size: 12px">
                      {{ item.qty_done }}
                    </div>
                  </template>
                  <template v-slot:[`item.description`]="{ item }">
                    <div style="width: 215px; font-size: 12px">
                      {{ item.description }}
                    </div>
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>

        <div
          v-if="isEdit"
          style="
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.1);
          "
        ></div>
      </v-card>
    </div>
    <div>
      <FormItemGr
        :act="act"
        :detail="detail"
        :itemDetail="itemDetail"
        :proc="proc"
        @loadPage="loadPage"
      />
    </div>
    <v-snackbar
      top
      elevation="0"
      color="white"
      v-model="snackbar"
      :timeout="timeout"
    >
      <v-alert outlined type="error" prominent border="left">
        {{ alertText }}
      </v-alert>
    </v-snackbar>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        background: rgba(0, 0, 0, 0.1);
      "
    >
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        class="d-flex justify-center align-center"
      >
        <p
          class="d-flex justify-center align-center"
          style="font-size: 12px; margin: auto"
        >
          Please wait
        </p>
      </v-progress-circular>
    </div>
  </v-container>
</template>
<script>
import 'vue-pdf-app/dist/icons/main.css'
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  components: {
    FormItemGr: () => import('./ItemForm')
  },
  data() {
    return {
      pageKey: 0,
      config: require('../../../services/config.json')[env],
      wWidth: window.innerWidth,
      oAuth: buildType.apiURL('oauth'),
      proc: buildType.apiURL('proc'),
      upload: buildType.apiURL('upload2'),
      isLoadingPage: true,
      pagePath: this.$route.params.path,
      isGrPageEdit: false,
      userLevel: null,
      permission: null,
      isEdit: false,
      pdfDialog: false,
      dokumenDialog: false,
      closeDialog: false,

      checkbox: false,
      detail: null,
      searchItem: '',
      itemsAction: [],
      status: null,
      actionValue: 0,
      selectedData: [],
      loading: false,
      loader: null,
      fileNameRab: '',

      itemEdit: null,

      isAllow: false,
      matrix: [],
      rules: {},
      snackbar: false,
      alertText: '',
      timeout: 2000,
      value: 0,
      counter: 0,
      timer: 100,
      isPdfOpen: false,
      selectedPdf: null,
      showPdf: false,
      file_subject: '',

      selectedItem: null,
      options: {},
      tab: null,
      items: [{ tab: 'Detail Barang', content: '' }],
      act: 'add',
      itemDetail: null,
      pjcaList: [],
      dropdown: {
        vendor: []
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getTabs']),
    grHeaders() {
      const arrHeaders = [
        // {
        //   text: 'No',
        //   value: 'no',
        //   align: 'left'
        //   // sortable: true,
        // },
        // {
        //   text: 'No. Payreq',
        //   value: 'payment_request',
        //   align: 'left'
        //   // sortable: false,
        // },
        {
          text: 'Kode Barang',
          value: 'product_code',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Nama Barang',
          value: 'product_name',
          align: 'left'
          // sortable: false,
        },
        // {
        //   text: 'Pembelian',
        //   value: 'qty_request',
        //   align: 'left'
        //   // sortable: false,
        // },
        // {
        //   text: 'Qty Return',
        //   value: 'return_qty',
        //   align: 'left',
        //   // sortable: true,
        //   sortable: false
        // },
        {
          text: 'Qty Diterima',
          value: 'qty_done',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Satuan',
          value: 'uom_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Keterangan',
          value: 'description',
          align: 'left',
          sortable: false
        }
      ]
      // if (this.detail.status.id === 0) {
      return arrHeaders
      // }
    }
  },
  watch: {
    selectedData() {
      this.itemsAction = [
        { id: 0, name: '' },
        { id: 1, name: 'Delete' }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.appLevel = this.config.application.ecatalogue.userLevel
    this.appLevel.administrator =
      this.config.application.all.userLevel.administrator
    this.getDataMenu()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapMutations([]),
    ...mapActions(['menu_access', 'getMenuId']),
    customSearch(value, search, item) {
      return Object.values(item).some(
        (v) => v && v.toString().toLowerCase().includes(search)
      )
    },
    showMsgDialog(
      pModalType,
      pStatusMsg,
      pBtnCancel,
      pInputType,
      pInputAtt,
      yesBtn,
      noBtn,
      pText
    ) {
      const title =
        pInputType === 'file'
          ? 'Upload bukti approvement pada kolom dibawah'
          : ''

      let additional = ''
      if (pText !== undefined) {
        additional = '<span style="font-size:12px;">' + pText + '</span>'
      }
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html:
              '<strong style="font-size:18px;">' +
              pStatusMsg +
              '</strong>' +
              '<br/>' +
              additional,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            input: pInputType !== undefined ? pInputType : null,
            inputLabel: pInputType !== undefined ? title : null,
            inputAttributes: pInputAtt !== undefined ? pInputAtt : null,
            confirmButtonText: yesBtn || 'Yes',
            cancelButtonText: noBtn || 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      console.log('Path>>>>>', routeModule)
      await this.getMenuId({
        keyword: '',
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.all.applicationId ||
                application.id ===
                  this.config.application.ecatalogue.applicationId
            )
            if (this.userLevel !== null) {
              this.checkMenuAccess([menuId, this.userLevel.id])
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    initDataForLayout() {
      this.isEdit = false

      this.loadPage()
    },
    nextPage() {
      this.loading = true
      try {
        const pageIndex = Number(this.$route.query.idx) + 1
        const path = `/e-catalogue/gr/${this.getGrList.grList[pageIndex].id}?idx=${pageIndex}`
        if (this.$route.path !== path) this.$router.push(path)
        setTimeout(() => {
          this.loadPage()
        }, 200)
      } catch (e) {
        this.loading = false
      }
    },
    previousPage() {
      this.loading = true
      try {
        const pageIndex = Number(this.$route.query.idx) - 1
        const path = `/e-catalogue/gr/${this.getGrList.grList[pageIndex].id}?idx=${pageIndex}`
        if (this.$route.path !== path) this.$router.push(path)
        setTimeout(() => {
          this.loadPage()
        }, 200)
      } catch (e) {
        this.loading = false
      }
    },
    async loadPage() {
      this.loading = true
      const url = `${this.proc}gr/detail/${this.$route.params.id}`
      await axios
        .get(url)
        .then((res) => {
          console.log('Detaill >>>', res)
          if (res.data.status_code == '00') {
            res.data.data.received_date = moment(
              res.data.data.received_date
            ).format('YYYY-MM-DD')
            this.detail = res.data.data
            if (res.data.data.status == 1) {
              // this.authorityCheck(res.data.data)
            }
            // this.dropdownDepartment()
            // this.dropdownShift()
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          this.loading = false
          return null
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async saveChange() {
      this.showMsgDialog('question', 'Simpan Perubahan', true).then(
        async (res) => {
          if (res.isConfirmed) {
            this.loading = true
            const form = {
              act: 'update',
              id: this.$route.params.id,
              received_by_name: this.detail.received_by_name,
              submitted_by_name: this.detail.submitted_by_name,
              received_date: this.detail.received_date,
              received_no: this.detail.received_no,
              po_no: this.detail.po_no,
              sj_no: this.detail.sj_no,
              form_no: this.detail.form_no
            }

            await axios
              .post(`${this.proc}gr/save`, form)
              .then((res) => {
                console.log(res.data)
                this.loading = false
                if (res.data.status_code == '00') {
                  this.loadPage()
                  this.showMsgDialog('success', res.data.status_msg, false)
                } else {
                  if (res.data.status_msg === 'Parameter value has problem') {
                    this.showMsgDialog(
                      'warning',
                      'Invalid input, Parameter value has problem',
                      false
                    )
                  } else {
                    this.showMsgDialog('warning', res.data.status_msg, false)
                  }
                }
                this.isEdit = false
              })
              .catch((err) => {
                console.log(err)
                this.loading = false
                this.isEdit = false
                this.showMsgDialog(
                  'error',
                  err
                    ? 'Something went wrong, Please contact an admin!'
                    : 'Something went wrong, Please contact an admin!',
                  false
                )
              })
          }
        }
      )
    },
    openDetailFpb(xData) {
      if (xData != null) {
        const arrItem = {
          list: []
        }
        this.$store.commit('setFpbList', arrItem)
        setTimeout(() => {
          window.open(`/e-catalogue/fpb-list/fpb/${xData.id}?idx=0`, '_blank')
          // this.$router.push(`/e-catalogue/fpb-list/fpb/${xData.id}?idx=0`)
        }, 200)
      }
    },
    openItemForm() {
      this.act = 'add'
      setTimeout(() => {
        document.getElementById('grItemForm').click()
      }, 300)
    },
    // initDropdown() {
    //   this.dropdownUnit()
    // },

    closePage() {
      this.detail = null
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      // this.$store.commit('setFpbId', null)
      // this.$router.push('/e-catalogue/' + this.$route.params.path)
      if (this.prevRoute !== undefined) {
        // if (this.prevRoute.path !== '/e-catalogue/cart') {
        // this.$router.push(this.prevRoute.path)
        // } else {
        this.$router.push(routeModule)
        // }
      } else {
        this.$router.push(routeModule)
      }
    },
    rowClick(pValue) {
      this.act = 'update'
      this.itemDetail = pValue
      setTimeout(() => {
        document.getElementById('grItemForm').click()
      }, 300)
    },
    editDetail() {
      this.isEdit = true
    },
    submitGr() {
      this.showMsgDialog(
        'warning',
        'Data tidak dapat dirubah bila sudah disubmit, lanjutkan ?',
        true,
        undefined,
        undefined,
        'Submit',
        'Batal',
        ''
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          axios
            .post(`${this.proc}gr/submit`, { id: this.detail.id })
            .then(async (res) => {
              this.loading = false
              if (res.data.status_code == '00') {
                this.loadPage()
                this.showMsgDialog('success', res.data.status_msg, false)
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'warning',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
              }
              // this.showMsgDialog('success', res.data.status_msg, false)
              // if (this.detail.approval_matrix !== null) {
              //   await this.fetch()
              //   console.log('fetch done')
              // } else {
              // }
            })
            .catch((err) => {
              this.loading = false
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    // confirmPayreq() {
    //   this.showMsgDialog(
    //     'question',
    //     'Approve payment request ?',
    //     true,
    //     undefined,
    //     undefined,
    //     'Confirm',
    //     'Batal',
    //     ''
    //   ).then((res) => {
    //     if (res.isConfirmed) {
    //       this.loading = true
    //       // const document_id = this.detail.approval_matrix[0].document_id
    //       axios
    //         .post(`${this.proc}payreq/confirm`, {
    //           document_id: this.detail.id
    //         })
    //         .then((res) => {
    //           this.loading = false
    //           if (res.data.status_code == '00') {
    //             this.loadPage()
    //             this.showMsgDialog('success', res.data.status_msg, false)
    //           } else {
    //             if (res.data.status_msg === 'Parameter value has problem') {
    //               this.showMsgDialog(
    //                 'warning',
    //                 'Invalid input, Parameter value has problem',
    //                 false
    //               )
    //             } else {
    //               this.showMsgDialog('warning', res.data.status_msg, false)
    //             }
    //           }
    //         })
    //         .catch((err) => {
    //           this.loading = false
    //           this.showMsgDialog(
    //             'error',
    //             err
    //               ? 'Something went wrong, Please contact an admin!'
    //               : 'Something went wrong, Please contact an admin!',
    //             false
    //           )
    //         })
    //     }
    //   })
    // },
    // rejectPayreq() {
    //   this.showMsgDialog(
    //     'question',
    //     'Reject payment request ?',
    //     true,
    //     undefined,
    //     undefined,
    //     'Reject',
    //     'Batal',
    //     ''
    //   ).then((res) => {
    //     if (res.isConfirmed) {
    //       this.loading = true
    //       // const document_id = this.detail.approval_matrix[0].document_id
    //       axios
    //         .post(`${this.proc}payreq/reject`, {
    //           document_id: this.detail.id
    //         })
    //         .then((res) => {
    //           this.loading = false

    //           if (res.data.status_code == '00') {
    //             this.loadPage()
    //             this.showMsgDialog('success', res.data.status_msg, false)
    //           } else {
    //             if (res.data.status_msg === 'Parameter value has problem') {
    //               this.showMsgDialog(
    //                 'warning',
    //                 'Invalid input, Parameter value has problem',
    //                 false
    //               )
    //             } else {
    //               this.showMsgDialog('warning', res.data.status_msg, false)
    //             }
    //           }
    //         })
    //         .catch((err) => {
    //           this.loading = false
    //           this.showMsgDialog(
    //             'error',
    //             err
    //               ? 'Something went wrong, Please contact an admin!'
    //               : 'Something went wrong, Please contact an admin!',
    //             false
    //           )
    //         })
    //     }
    //   })
    // },
    cancelGr() {
      this.showMsgDialog(
        'question',
        'Cancel dokumen ?',
        true,
        undefined,
        undefined,
        'Ya',
        'Batal',
        ''
      ).then((res) => {
        if (res.isConfirmed) {
          this.loading = true
          // const document_id = this.detail.approval_matrix[0].document_id
          axios
            .post(`${this.proc}gr/cancel`, {
              id: this.detail.id
            })
            .then((res) => {
              this.loading = false

              if (res.data.status_code == '00') {
                this.loadPage()
                this.showMsgDialog('success', res.data.status_msg, false)
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'warning',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
              }
            })
            .catch((err) => {
              this.loading = false
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    setDraftGr() {
      this.showMsgDialog(
        'question',
        'Set to draft dokumen ?',
        true,
        undefined,
        undefined,
        'Simpan',
        'Batal',
        ''
      ).then((res) => {
        if (res.isConfirmed) {
          this.loading = true
          // const document_id = this.detail.approval_matrix[0].document_id
          axios
            .post(`${this.proc}gr/set_to_draft`, {
              id: this.detail.id
            })
            .then((res) => {
              this.loading = false

              if (res.data.status_code == '00') {
                this.loadPage()
                this.showMsgDialog('success', res.data.status_msg, false)
              } else {
                if (res.data.status_msg === 'Parameter value has problem') {
                  this.showMsgDialog(
                    'warning',
                    'Invalid input, Parameter value has problem',
                    false
                  )
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
              }
            })
            .catch((err) => {
              this.loading = false
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    itemDelete(item, index) {
      console.log('this.selectedData', this.selectedData)
      this.showMsgDialog(
        'question',
        'Item tersebut akan dihapus, Anda yakin ?',
        true
      ).then((res) => {
        if (res.isConfirmed) {
          for (let i = 0; i < this.selectedData.length; i++) {
            axios
              .delete(`${this.proc}gr/delete_detail/${this.selectedData[i].id}`)
              .then((res) => {
                console.log('delete>>>>', res)
                if (i === this.selectedData.length - 1) {
                  if (res.data.status_code === '00') {
                    this.showMsgDialog('success', res.data.status_msg, false)
                  } else {
                    this.showMsgDialog('error', res.data.status_msg, false)
                  }
                  setTimeout(() => {
                    this.actionValue = 0
                    this.loadPage()
                  }, 200)
                }
              })
              .catch((err) => {
                if (i === this.selectedData.length - 1) {
                  this.showMsgDialog('error', err.message, false)
                  setTimeout(() => {
                    this.actionValue = 0
                  }, 200)
                }
              })
          }
        } else {
          this.actionValue = 0
        }
      })
    },
    actionSelect(event) {
      switch (event) {
        case 1:
          this.itemDelete()
          break
      }
    },
    statusName(val) {
      const arrType = ['DRAFT', 'DONE', 'CANCEL']
      return arrType[val]
    },
    statusColor(val) {
      const arrType = ['grey', 'green', 'red']
      return arrType[val]
    },
    convertDate(val) {
      const raw = val
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fpb-title {
  position: relative;
  top: 10px;
  margin: 0;
  // width: 50%;
  font-weight: bold;
  // text-align: center;
  font-size: 20px;
}
.items-total-div-2 {
  // padding: 10px 0;
  width: '40%';
  .items-total-box {
    border: 1px solid #e0e0e0;
    padding: 20px 10px;
    width: 400px;
    display: flex;
    margin-left: auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-size: 14px;
    div {
      border-bottom: 1px solid #e0e0e0;
      width: 100%;
      padding-top: 20px;
      display: flex;
      p {
        margin: 0;
        margin-right: 10px;
        min-width: 80px;
        position: relative;
        span {
          position: absolute;
          right: 0;
        }
      }
    }
  }
}
.items-total-div-3 {
  padding: 0 10px;
  width: 60%;
  font-size: 14px;
}
.actionButton {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.fpb-status-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.category-line {
  margin: 10px 0 10px 0;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  line-height: 75px;
}
@media (max-width: 1200px) {
  .category-line-item {
    margin-bottom: 10px;
  }
}
@media (max-width: 808px) {
  .actionButton {
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 2;
    padding: 10px 0;
  }
  .items-total-div-2 {
    width: '100%';
    .items-total-box {
      width: 100%;
    }
  }

  .items-total-div-3 {
    margin-top: 10px;
    width: 100%;
  }
  .tipe-proses {
    margin-top: 5px;
  }
}

@media (max-width: 840px) {
  .fpb-status-section {
    // flex-direction: column;
    flex-wrap: wrap;
  }
}
@media (max-width: 780px) {
  .fpb-title {
    font-size: 3vw;
  }
}
</style>
